const baseStyle = {
  multiline: true,
  px: 4,
  py: 2,
  totalLines: 4,
  _ios: {
    h: 20,
  },
};

export default { baseStyle };
